<template>
    <v-main  >
    <v-app-bar app color="grey lighten-5" flat> 

      <v-app-bar-nav-icon @click="drawer = true" v-if="!breakpoint.lgAndUp"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
        <router-link to="/">
        <img src="@/assets/logo.png" />
        </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon style="opacity:0" v-if="!breakpoint.lgAndUp"></v-app-bar-nav-icon>

      

    </v-app-bar>
      <v-layout d-flex column class="fh">
        <v-expand-transition>
          <v-flex shrink v-if="alert.active">
                <v-alert class="mb-0" text dense dark v-if="alert.active" :icon="alert.icon" :color="alert.color" >
                  {{ alert.message }}
                  <v-btn rounded depressed color="secondary" link text :loading="alert.action.loading" v-if="alert.action.text && alert.action.click" @click="alert.action.click">
                    {{ alert.action.text }}
                  </v-btn>
                </v-alert>
          </v-flex>
        </v-expand-transition>
        <v-flex grow >
          <router-view></router-view>
        </v-flex>
      </v-layout>
  



      <v-navigation-drawer :permanent="$vuetify.breakpoint.lgAndUp" app :value="drawer || $vuetify.breakpoint.lgAndUp" @input="drawer = $event" :right="$vuetify.rtl"  >
        <v-card flat tile light>
          <v-card-text class="pa-0" v-if="currentUser">

            <v-card flat dark tile color="primary">
              <v-card-text class="text-center">
                <v-avatar size="80">
                  <img :src="src" />
                </v-avatar>
                <v-list-item >
                <v-list-item-content>
                    <v-list-item-title>
                      {{ currentUser.displayName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{currentUser.email}}
                    </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>  
              </v-card-text>
              <v-card-actions>
                <v-btn small text @click="logout" > 
                    <v-icon small>mdi-logout</v-icon>
                    تسجيل الخروج
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small text to="/dashboard/profile"> 
                    <v-icon small>mdi-account</v-icon>
                    حسابي
                </v-btn>
              </v-card-actions>
              
            </v-card>
            <v-divider></v-divider>
              
            <v-list shaped  v-if="currentUserClaims">
              <v-subheader>
                القائمة
              
              </v-subheader>
              <v-list-item-group
                v-model="selectedItem"
                color="primary"
              >
                <v-list-item
                  v-for="(item, i) in menu"
                  :key="i"
                  :to="item.to"
                  class="mb-2"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </v-main>
</template>
<script>
  export default {
    data: () => ({
      drawer: false,
      group: null,
      selectedItem: 1,
      alert: {
        active: false,
        color: 'warning',
        icon: 'mdi-alert',
        message: '',
        action: {
          text: null,
          click: null,
          loading: false
        } 
      }
    }),
    watch: {
      currentUser: {
        handler (user) {
          if (!user) return
           if (!user.email) {
            this.setAlertAction('تعديل بريدك الالكتروني', () => {
              this.$router.replace('/dashboard/profile?edit=email')
            })
            this.showAlert('لم يتم ربط حسابك الى بريد الكتروني.!')
          } else
            if (!user.emailVerified) {
              this.setAlertAction('تأكيد البريد الالكتروني', this.verifyEmail)
              this.showAlert('لم يتم التحقق من بريدك الإلكتروني.!')
            }
        },

        immediate: true
      }
    },
    computed: {
      src () {
        const { currentUser } = this
        return currentUser && currentUser.photoURL ? currentUser.photoURL : require('@/assets/profile-img.png')
      },
      menu () {
        return [
          { text: this.$t('my_event'), icon: 'mdi-string-lights', to:"/dashboard", roles: 'account' },
          // { text: this.$t('dashboard'), icon: 'mdi-view-dashboard', to:"/dashboard", roles: 'admin' },
          { text: this.$t('events'), icon: 'mdi-string-lights', to:"/dashboard/events", roles: 'admin' },
          { text: this.$t('event_guests'), icon: ' mdi-clipboard-list-outline', to:"/dashboard/guests", roles: 'account' },
          { text: this.$t('event_seats'), icon: ' mdi-table-chair', to:"/dashboard/seats", roles: 'admin' },
          { text: this.$t('event_seats'), icon: ' mdi-table-chair', to:"/dashboard/seats/"+this.currentUser.uid, roles: 'account' },
          // { text: this.$t('send_invitations'), icon: 'mdi-card-account-details-star-outline', to:"/dashboard/invitations" },
          { text: this.$t('users'), icon: 'mdi-account-circle', to:"/dashboard/users", roles: 'admin' },
        ].filter(item => {
          if (!item.roles) return true

          return this.hasRoles(item.roles)

        })
       
      }
    },
    methods: {
      setAlertAction (text, click) {
        this.alert.action.text = text
        this.alert.action.click = click
      },
      showAlert(msg, color = 'warning', icon = "mdi-alert") {
          this.alert.active = true
          this.alert.message = msg
          this.alert.icon = icon
          this.alert.color = color
      },
      hideAlert() {
          this.alert.active = false
          this.alert.message = ''
          this.alert.icon = 'mdi-alert'
          this.alert.color = 'warning'
          this.alert.action.text = null
          this.alert.action.click = null
      },
      verifyEmail () {
        this.alert.action.loading = true
        this.sendEmailVerification().then(() => {
          this.notify('تم أرسال رابط التأكيد الى بريدك الالكتروني.', { color: 'success', icon: 'mdi-check-circle' })
          this.hideAlert()
        }).catch(this.handelError).finally(() => this.alert.action.loading = false)

      }
    }
  }
</script>